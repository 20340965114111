import React, { Component } from 'react'
import ProgressLoader from './loader/ProgressLoader';
import axios from '../helper/Axios';
import queryString from 'query-string';

//assets
import line_logo from '../../static/images/line-logo.png';
import server_error from '../../static/images/server_error.svg';
import image_url_map from '../../static/images/constants/image_constants';
const amber_hi = image_url_map['amber_hi'];

class LineLoginOpt extends Component {
    state={
        request_status_code: 0, //0 - Placing request, 1- Success, 2 - error
        message: '',
        request_status_title: '',
        button_link: ''
    }

    componentDidMount() {
        this.setPramams()
        this.makeOptRquest(1)
    }

    setPramams = () => {
        let params = queryString.parse(window.location.search)
        if(params.state) {
            var str = '';
            for (var i = 0; i < params.state.length; i += 2) str += String.fromCharCode(parseInt(params.state.substr(i, 2), 16));
            sessionStorage.token =  str;
        }
    }

    makeOptRquest = async() => {
        const params = queryString.parse(window.location.search)
        if(sessionStorage.token) {
            try{
                const response = await axios.get(`/v1/users/line/login?is_accessible=true`, {params:{code:params.code, friendship_status_changed: params.friendship_status_changed}})
                if(response && response.status === 200) {
                    if(response.data.button_link) {
                        this.setState({
                            message: response.data.message,
                            request_status_title: response.data.status,
                            request_status_code: 1,
                            button_link: response.data.button_link
                        })
                    } else {
                        this.setState({
                            message: response.data.message,
                            request_status_title: response.data.status,
                            request_status_code: 1
                        })
                    }
                }
            } catch (response){
                this.setState({request_status_code: 2})
            }
        } else {
            this.setState({
                request_status_code: 2
            })
        }
    }

    render() {
        const { request_status_title, message, request_status_code, button_link } = this.state
        return (
            <>
            <div className="content whatsapp-content has-text-centered columns pd-top-75">
                {
                    request_status_code === 0 ?
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <p className="mg-bottom-10">Please wait while we are raising your request...</p>
                            <ProgressLoader />
                        </div>
                    </div>
                    : 
                    request_status_code !== 2 ?
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <img width="55%" src={line_logo} alt = "line"/>
                            <p className="is-size-4 mg-bottom-15 has-text-weight-bold">{request_status_title}</p>
                            <p>
                                {message}
                            </p>
                            {
                                button_link ? 
                                    <p>
                                        <a className='font-14' href={button_link}>
                                            <img src="https://scdn.line-apps.com/n/line_add_friends/btn/en.png" alt="Add friend" width={`115px`} height="36" border="0"/>
                                        </a>
                                    </p>
                                : null
                            }
                        </div>
                    </div> : 
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <img width="17%" src={server_error} alt = "server_error"/>
                            <p className="is-size-4 mg-bottom-15 has-text-weight-bold">Sorry!</p>
                            <p>
                                It's not you.. Its's us.<br/>
                                We're experiencing an <strong>internal server problem</strong>.
                                Please try again later or contact <a className="has-text-info" href="mailto:support@infeedo.com">support@infeedo.com</a>
                            </p>
                        </div>
                    </div>
                }
            </div>
            <img className="amber-hi" src={amber_hi} alt = "amber"/>
            </>
        )
    }
}

export default LineLoginOpt